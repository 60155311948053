<template>
  <a-auto-complete
    :value="value"
    option-label-prop="value"
    :filter-option="filterOption"
    :allowClear="true"
    @search="onSearch"
    @select="onSelect"
  >
    <template slot="dataSource">
      <a-select-option v-for="item in dataSource" :key="item.id" :value="String(item.id)" :item="item">
        {{ item.customer_contact }}
        <div style="font-size: 12px; color: #999">电话: {{ item.contact_phone }}</div>
        <div style="font-size: 12px; color: #999">地址: {{ item.receiving_address }}</div>
      </a-select-option>
    </template>
  </a-auto-complete>
</template>

<script>
import { deliveryClientOption } from "@/api/option";

export default {
  props: ["value", "client"],
  model: { prop: "value", event: "change" },
  data() {
    return {
      dataSource: [],
    };
  },
  methods: {
    initData() {
      deliveryClientOption({ page_size: 100, client: this.client }).then((data) => {
        this.dataSource = data.results;
      });
    },
    filterOption(input, option) {
      const optionLabel = option.componentOptions.children[0].text.toLowerCase();
      const inputText = input.toLowerCase();

      return optionLabel.indexOf(inputText) !== -1;
    },
    onSearch(value) {
      this.$emit("change", value);
    },
    onSelect(value, option) {
      this.$emit("change", option.data.attrs.item.customer_contact);
      this.$emit("select", option.data.attrs.item);
    },
  },
  mounted() {
    this.initData();
  },
  watch: {
    client() {
      this.initData();
    },
  },
};
</script>
